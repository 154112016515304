<template>
    <div class="table__block table-responsive">

        <table class="table table-hover  table-borderless">
            <thead>
                <tr>
                    <th scope="col"> {{ $t("contracts.contract") }} </th>
                    <th scope="col"> {{ $t("contracts.customer") }} </th>
                    <th scope="col"> {{ $t("contracts.supplier") }} </th>
                    <th scope="col"> {{ $t("contracts.system") }} </th>
                    <th scope="col"> {{ $t("contracts.acceptdate") }} </th>
                    <th scope="col"> {{ $t("contracts.sum") }} </th>
                </tr>
            </thead>
            <tbody>

                <tr v-for="contract in items" :key="contract.id">
                    <td>
                        <a href="#" @click.prevent="showContract(contract.id)"> 
                            {{contract.number}} 
                        </a>
                    </td>
                    <td> 
                        <a href="#" @click.prevent="showContract(contract.id)"> 
                            {{ translateObject(contract, 'customerNameRu', 'customerNameKz') }} 
                        </a>
                    </td>
                    <td> 
                        <a href="#" @click.prevent="showContract(contract.id)"> 
                            {{ translateObject(contract, 'providerNameRu', 'providerNameKz') }} 
                        </a>
                    </td>
                    <td> {{ translateObject(contract, 'systemNameRu', 'systemNameKz') }} </td>
                    <td> {{contract.acceptDate}} </td>
                    <td> {{contract.sum  | sum }} </td>
                </tr>
                <shops-modal v-if="contractInfo" :contract="contractInfo" />
                
            </tbody>
        </table>
    </div>
</template>

<script>

import shopsModal from '../contractPage/shopsModal'
import translateMix from '@/mixins/translate'

export default {
    props: ['items',],
    name: 'shops-table',
    components: {
        shopsModal
    },
    mixins: [
        translateMix
    ],
    data: () => ({
        contractInfo: null
    }),
    mounted() {
    },
    methods: {
        showContract(id){
            let params = {
                entity: "ContractTitle",
                uuid: id
            }

            this.$store.dispatch('CLICKED_LIST', params)
                .then(res => {
                    this.contractInfo = res.data;
                    this.$modal.show('shops-modal');
                })
                .catch(() => {
                    this.contractInfo = null;
                    this.$toast.error(this.$t("message.nothing_found"));
                })
                ;
        }
    },
    filters: {
        monthName(num) {
            let month = +num
            month = month - 1

            var arr=['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь', 'Ноябрь','Декабрь',]
            
            if(month > 12) {
                return ''
            } else {
                return arr[month]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
table {
    font-size: 14px;
}
table tbody tr th{
    cursor: pointer;
}
</style>