<template>
    <modal
        :name="boxName"
        :width="boxWidth"
        :height="boxHeight"
        :adaptive="true"
        >
        <div class="shops-modal">
            <div class="text__area" v-if="contract">
                <h4 class="text-center" > {{ $t("contracts.contract") }} - № {{contract.number}} </h4>

                <p class="close" @click="hide"><i class="fas fa-times"></i></p>

                <div class="shops-modal__content">
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("contracts.customer") }} </div>
                        <div class="col-sm-7 col-12"> {{contract.customerNameRu}} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("contracts.provider") }} </div>
                        <div class="col-sm-7 col-12"> {{contract.providerNameRu}} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("contracts.sum") }} </div>
                        <div class="col-sm-7 col-12"> {{contract.sum}} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("contracts.acceptdate") }} </div>
                        <div class="col-sm-7 col-12">{{contract.acceptdate}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("contracts.system") }} </div>
                        <div class="col-sm-7 col-12"> 
                            {{contract.systemNameRu}} 
                            (<a target="blank" :href="getLink"> {{ $t("view.redirectSource") }} </a>)
                        </div>
                    </div>
                </div>

                <button class="btn btn-close" @click="hide">{{ $t("general.close") }}</button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'contract-modal-box',
    props: {
        boxName: {
            type: String,
            required: true
        },
        boxWidth: {
            type: Number,
            default: 1200
        },
        boxHeight: {
            type: Number,
            default: 600
        },
        contract: {
            type: Object
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        show() {
            this.$modal.show(this.boxName);
        },
        hide() {
            this.$modal.hide(this.boxName);
        },
    },
    computed: {
        getLink() {
            const url = this.contract.system.contractUrl;
            const str = url.replace('@@@', `${this.contract.externalId}`);
            return str;
        }
    }
}
</script>

<style lang="scss" scoped>
.shops-modal {
    &__content{
        margin: 20px 0;
        // border: 1px solid #ccc;
        border: 1px solid rgb(231, 231, 231);
        border-radius: 5px;
        .row {
            margin: 0;
            font-size: 14px;
            border-bottom: 1px solid rgb(231, 231, 231);
            &:first-child{
                a {
                    display: inline;
                    color: #553DB4;
                }
            }
            &:last-child{
                border-bottom: none;
            }
            .col-12 {
                padding: 12px 15px;
                @media(max-width: 575px) {
                    text-align: center;
                }
            }
            .col-sm-5 {
                border-right: 1px solid #ccc;
                text-align: right;
                @media(max-width: 575px) {
                    text-align: center;
                    border-right: none;
                }
            }
            &:nth-child(odd) {
                background: rgb(241, 241, 241);
            }
        }
    }
}
.text__area {
    padding: 50px;
}
.btn-close {
    background: #FFAD00;
    display: block;
    color: #fff;
    width: 300px;
    margin: 0 auto;
}
    
</style>