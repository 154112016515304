<template>
    <div class="right-side">
        <template v-if="isAuth">
            <div v-if="hasTariff">
            <h2> {{$t("navigation.contracts")}}  </h2>
            <form class="seach__area " @submit.prevent="searchHandler">
                <div class="search__block-one">
                    <input type="text" class="form-control" v-model="filter.name" :placeholder="$t('contracts.contractnumber')">
                    <button type="submit" class="btn "> {{$t('search.search_btn')}} </button>
                </div>
                <div class="search__block-two">
                    <div>
                        <a class="search-hide" @click="advancedSearch = !advancedSearch"> {{ $t('search.advanced_search') }} &nbsp; 
                            <img class="arrowdown" :class="{active: advancedSearch}" src="@/assets/img/icons/arrowDown.svg" alt="">
                        </a>
                    </div>
                </div>
                <div class="search__block-hide" v-if="advancedSearch">
                    <div class="form-row">
                        <div class="col-12 col-lg-8">
                            <input type="text" class="form-control" v-model="filter.customer" :placeholder="$t('contracts.customerbin')">
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="input-group">
                            <input type="text" class="form-control" v-model="filter.priceFrom" :placeholder="$t('search.pricefrom')">
                            <input type="text" class="form-control" v-model="filter.priceTo" :placeholder="$t('search.priceto')">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12 col-lg-8">
                            <div>
                                <input type="text" class="form-control " v-model="filter.supplier" :placeholder="$t('contracts.supplierbin')">
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="filter.contractFrom" :placeholder="$t('contracts.dateContractBegin')">
                                <input type="text" class="form-control" v-model="filter.contractTo" :placeholder="$t('contracts.dateContractEnd')">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12">
                            <v-select 
                                class="style-chooser"
                                v-if="purchaseAreas"
                                :label="getFieldName('name')"
                                multiple
                                v-model="filter.systemId"
                                :reduce="prefix => prefix.id"
                                :placeholder="$t('contracts.system')"
                                :options="purchaseAreas">
                            </v-select> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <v-select 
                                class="style-chooser"
                                v-if="tradeMethod"
                                :label="getFieldName('name')"
                                multiple
                                v-model="filter.tradeMethodId"
                                :reduce="prefix => prefix.id"
                                :placeholder="$t('contracts.method')"
                                :options="tradeMethod">
                            </v-select> 
                        </div>
                        <div class="col-12">
                            <v-select 
                                class="style-chooser"
                                v-if="contractType"
                                :label="getFieldName('name')"
                                multiple
                                v-model="filter.contractTypeId"
                                :reduce="prefix => prefix.id"
                                :placeholder="$t('contracts.contractType')"
                                :options="contractType">
                            </v-select> 
                        </div>
                    </div>
                    
                    <div class="search__block-two">
                        <div>
                            
                        </div>
                        <div>
                            <button type="reset" @click="resetHandler" class="btn btn__reset"> {{$t('search.reset')}} </button>
                            <button type="submit" class="btn btn__search"> {{ $t('search.search_btn') }} </button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="table-area">
                <div class="paginator-area">
                    <div class="paginator">
                        <paginate
                            v-model="currentPage"
                            :page-count="pageCount"
                            :click-handler="changePage"
                            :prev-text="'&laquo;'"
                            :next-text="'&raquo;'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :page-link-class="'page-link'"
                            :prev-class="'page-item'"
                            :prev-link-class="'page-link'"
                            :next-class="'page-item'"
                            :next-link-class="'page-link'"
                            >
                        </paginate>
                    </div>
                    <div class="sort-area">
                        <p> {{$t('sort.sort_by')}} </p>
                        <div class="sort-area__select">
                            <v-select 
                                @input="sortHandler"
                                class="style-chooser"
                                v-if="options"
                                label="title"
                                v-model="selected"
                                :reduce="prefix => prefix.sortBy"
                                :placeholder="$t('mainPage.choose')"
                                :options="options"
                            >
                                <template slot="option" slot-scope="option">
                                    {{ option.title }}
                                    <span class="ml-3" :class="option.icon"></span>
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
                <div>
                    <beat-loader 
                        v-if="loading" 
                        class="loader-center" 
                        :color="'#553DB4'" 
                        :loading="loading" 
                        :size="20" 
                        :sizeUnit="'px'"
                    /> 
                    <shops-table 
                        v-else 
                        :items="this.content.items"
                    />
                    <div v-if="this.content.items == 0" class="text-center not-found ">
                        {{ $t("message.nothing_found") }}
                        <button type="reset" @click="resetHandler" class="btn btn__reset ml-2"> {{$t('search.reset')}} </button>
                    </div>
                </div>
                <div class="paginator-area">
                    <div class="paginator">
                        <paginate
                        v-model="currentPage"
                            :page-count="pageCount"
                            :click-handler="changePage"
                            :prev-text="'&laquo;'"
                            :next-text="'&raquo;'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :page-link-class="'page-link'"
                            :prev-class="'page-item'"
                            :prev-link-class="'page-link'"
                            :next-class="'page-item'"
                            :next-link-class="'page-link'"
                            >
                        </paginate>
                    </div>
                    <div class="quantity__text">
                        <p>{{$t('plan.row_cnt')}}: {{this.content.totalElements | sum}}</p>
                    </div>
                </div>
            </div>
            </div>
            
            <div v-else class="alert alert-danger py-5" role="alert">
                <p class="text-center">{{$t("message.noTariffPlan")}}</p>
            </div>

            <modal-box boxName="contract-info" ref="modalContract" :contract="contractInfo"></modal-box>
        </template>
        <div v-else class="alert alert-danger py-5" role="alert">
            <p class="text-center">{{$t("message.isNotAuth")}}</p>
        </div>
    </div>
</template>

<script>

import shopsTable from '@/components/contractPage/shopsTable'
import ModalBox from '@/components/ui/ContractModalBox'
import { mixin as clickaway } from "vue-clickaway"
import { mapGetters } from 'vuex'
import translateMix from '@/mixins/translate'



    export default {
        name: 'purchase-contracts',
        mixins: [
            clickaway,
            translateMix
        ],
        components: {
            shopsTable, 
            ModalBox
        },
        props: {
            keywords: String
        },
        data: () => ({
            contractInfo: null,
            content: {
                totalElements: null,
                items: null
            },
            selected: null,
            loading: true,
            advancedSearch: false,
            size: 15,
            currentPage: 1,
            allTru: null,
            filter: {
                name: null,
                customer: null,
                supplier: null,
                priceFrom: null,
                priceTo: null,
                tradeMethodId: null,
                contractTypeId: null,
                contractFrom: null,
                contractTo: null,
                systemId: null,
                tradeMethodGroup: null

            },
            dSystemId: null,
            purchaseAreas: null,
            tradeMethod: null,
            contractType: null

        }),
        mounted() {
            this.onShow();
        },
        methods: {
            onShow() {
                if (this.$route.query.keywords) {
                    this.filter.name = this.$route.query.keywords;
                }

                this.getContracts();
                this.getPurchaseArea();
                this.getTradeMethod();
                this.getOrderType();
                this.$store.commit('RESET_CLICKED_CITY');
                
                if (this.$route.params.id) {
                    this.showContract(this.$route.params.id);
                }
            },
            getContract(id) {
                let params = {
                    entity: "ContractTitle",
                    uuid: id
                };

                this.$store.dispatch('CLICKED_LIST', params)
                    .then(res => {
                        this.contractInfo = res.data
                        this.$refs.modalContract.show();
                    })
                    .catch(() => {
                        this.clickedRow = null
                        this.contractInfo = null
                        this.$toast.error(this.$t("message.nothing_found"));
                    })
                    ;
            },
            showContract(id) {
                this.getContract(id);
            },
            getOrderType() {
                let params = {
                    entity: 'Taxonomy',
                    filter: {tableField: "contract_status"},
                    page: 0,
                    length: 500,
                }
                this.$store.dispatch('GET_STATUS', params)
                    .then( res => {
                        this.contractType = res.content
                    })
                    ;
            },
            getTradeMethod() {
                let params = {
                    entity: 'MethodTrade',
                    filter: {},
                    page: 0,
                    length: 100,
                }
                this.$store.dispatch('GET_METHOD_TRADE', params)
                    .then( res => {
                        this.tradeMethod = res.content
                    })
            },
            getPurchaseArea() {
                let params = {
                    entity: 'DictionaryItem',
                    filter: {'dictionaryId.code': "System"},
                    page: 0,
                    length: 50,
                }
                this.$store.dispatch('GET_PURCHASE_AREA', params)
                    .then( res => {
                        this.purchaseAreas = res.content
                    })
            },
            searchHandler(){
                this.getContracts()
            },
            resetHandler() {
                for (let val in this.filter) {
                    this.filter[val] = null
                }
                this.allTru = null
                this.currentPage = 1
                this.$store.commit('RESET_CLICKED_CITY')
                this.getContracts()
            },
            sortHandler(){
                this.currentPage = 1
                this.getContracts()
            },
            changePage(currentPage) {
                this.currentPage = currentPage
                this.getContracts()
            },
            getContracts() {
                this.loading = true;
                let params = {
                    page: this.currentPage - 1,
                    entity: 'OrderDetail',
                    length: 15,
                    filter: {}
                }

                if (this.selected) {
                    params.sort = [this.selected]
                }

                if ( this.filter ) {
                    for (const key in this.filter) {
                        if(this.filter[key]) {
                            params.filter[key] = this.filter[key]
                        }
                    }
                }

                 this.$store.dispatch('GET_LIST', params) 
                    .then((res) => {
                        this.content.items = res.data.content
                        this.content.totalElements = res.data.totalElements
                        this.loading = false
                    })
            },
            away() {
                this.allTru = null
            }
        },
        watch: {
            isAuth(val, oldVal) {
                if (val === true && oldVal === false){
                    this.onShow();
                }
            }
        },
        computed: {
            ...mapGetters([
                'USER_TARIFF',
                'isAuth'
            ]),
            hasTariff() {
                return this.USER_TARIFF && this.USER_TARIFF.code !== 'FREE' && this.USER_TARIFF.code !== 'DEMO';
            },
            options() {
                return [
                    {
                        title: this.$t('sort.by_area'),
                        icon: 'fas fa-sort-numeric-down',
                        sortBy: {
                            field: 'system',
                            by: 'asc',
                        },
                    },
                    {
                        title: this.$t('sort.by_area'),
                        icon: 'fas fa-sort-numeric-up',
                        sortBy: {
                            field: 'system',
                            by: 'desc',
                        },
                    },
                    {
                        title: this.$t('sort.by_sum'),
                        icon: 'fas fa-sort-numeric-down',
                        sortBy: {
                            field: 'sum',
                            by: 'asc',
                        },
                    },
                    {
                        title: this.$t('sort.by_sum'),
                        icon: 'fas fa-sort-numeric-up',
                        sortBy: {
                            field: 'sum',
                            by: 'desc',
                        },
                    },
                    {
                        title: this.$t('sort.by_datee'),
                        icon: 'fas fa-sort-numeric-down',
                        sortBy: {
                            field: 'date',
                            by: 'asc',
                        },
                    },
                    {
                        title: this.$t('sort.by_datee'),
                        icon: 'fas fa-sort-numeric-up',
                        sortBy: {
                            field: 'date',
                            by: 'desc',
                        },
                    },
                ];
            },
            pageCount() {
                let l = this.content.totalElements
                let s = this.size
                return Math.ceil(l/s)
            },
            length_() {
                if (this.content.items) {
                    return true
                }

                return false
            }

        }
    }
</script>

<style lang="scss" scoped>
    .arrowdown.active {
        transform: rotate(180deg);
    }
    .block__flex1 {
        .style-chooser {
            width: 100%;
        }
    }

    .tru-block{
        border: 1px solid #ced4da;
        padding: 10px;
        width: 100%;
        background: #fff;
        height: 200px;
        overflow-y: scroll;
        border-radius: 5px;
        margin-top: 10px;
        &__item {
            padding: 10px;
            cursor: pointer;
            border: 1px solid transparent;
            display: flex;
            p {
                font-size: 14px;
                margin-bottom: 10px;
                &:first-child {
                    margin-right: 15px;
                }
            }
            &:nth-child(odd) {
                background: #F0F1F7;
            }
            &:hover {
                border: 1px solid #ccc;
            }
        }
    }
    .block__flex {
        div {
            width: 100%;
            &:first-child {
                margin-right: 10px;
            }
        }
        @media (max-width: 770px) {
            div {
                margin-bottom: 10px;
                &:first-child{
                    margin-right: 10px
                }
            }
        }
    }

    
    
</style>